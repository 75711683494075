import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DateControlsModule } from 'src/app/common/calendar/date-controls/date-controls.module';
import { ImageColumnsModule } from 'src/app/common/image-columns/image-columns.module';
import { TranslateDirectiveModule } from 'src/app/common/translate-directive/translate-directive.module';
import { AeronetChinaComponent } from './aeronet-china/aeronet-china.component';
import { AeronetKoreaComponent } from './aeronet-korea/aeronet-korea.component';
import { KalionKoreaComponent } from './kalion-korea/kalion-korea.component';
import { PandoraSnuComponent } from './pandora-snu/pandora-snu.component';
import { PandoraUnistComponent } from './pandora-unist/pandora-unist.component';

@NgModule({
  declarations: [AeronetKoreaComponent, AeronetChinaComponent, KalionKoreaComponent, PandoraSnuComponent, PandoraUnistComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslateDirectiveModule,
    DateControlsModule,
    ImageColumnsModule
  ]
})
export class GroundBasedRemoteSensingdataModule { }
