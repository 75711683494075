import { ChangeDetectorRef, Component } from '@angular/core';
import * as dateFns from "date-fns";

@Component({
  selector: 'app-pandora',
  templateUrl: './pandora-snu.component.html',
  styleUrls: ['./pandora-snu.component.css']
})
export class PandoraSnuComponent {

  current:any = 0;
  selectedDate: Date = new Date();

  rootUrl = "https://finep.miraeclimate.com/image/";

  imageArray!:any[];

  constructor(private changeRef: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.changeRef.detectChanges();
  }

  titles: string[] = [
    "Hourly NO2 Surface Concentration (KST)", "Hourly NO2 Total Vertical Column (KST)", "Hourly O3 Total Vertical Column (KST)"
  ]

  filenames: string[] = [
    "P149_NO2_Surface",
    "P149_NO2_TCD",
    "P149_O3_TCD"
  ]

  makeImageArray(): any{  
    this.imageArray = [];

    this.filenames.map((keyword:string, index:number) => {
      this.imageArray.push({
        title: this.titles[index],
        src: this.rootUrl+["Seoul-SNU",keyword, dateFns.format(this.selectedDate, "yyyyMMdd") +"_Hourly_KST.png"].join("_")
      })
    });
  }

  getDateChange(date: Date){
    this.selectedDate = date;
    this.makeImageArray();
  } 

}
