<div class="mx-6">
    <div class="my-6">
        <app-schedule></app-schedule>
    </div>
    <div class="content">
        <h2>1. Data sharing</h2>
        <div class="messageis-dark">
            <div class="message-body">
                <ul>
                    <li>Preliminary data should be shared the day after observation</li>
                    <li>Please add a README file to a repository</li>
                    <li>Each data file should contain header information in the top row and the first column
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="content">
        <h2>2. Daily briefing</h2>

        <div class="messageis-dark">
            <div class="message-body">
                <ul>
                    <li>Any information (e.g. preliminary analysis and very recent paper) could be introduced
                        during daily briefing</li>
                    <li>All participants are encouraged to attend the daily briefing during the campaign</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="content">
        <h2>3. Quarantine prevention guidance for the international teams</h2>
        <div class="columns">
            <div class="column">
                <div class="messageis-dark">
                    <div class="message-body">
                        <h3>Before departure</h3>    
                        <ul>
                            <li>Quarantine Exemption Certificate will be issued from the relevant Korean diplomatic
                                office in your region</li>
                            <li>Prepare with a negative result of PCR test for COVID-19 issued within 72 hours prior to
                                the departure date </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="messageis-dark">
                    <div class="message-body">
                        <h3>After arrival</h3>
                        <ul>
                            <li> Two more PCR tests at a public healthcare center in Korea are mandatory to be taken
                                <p class="is-size-6">(*Costs of the testing conducted in Korea will be covered by the
                                    Korean government)</p>
                            </li>
                            <li>Check your health conditions everyday on the Self-Diagnosis App. as following to Daily
                                Active Monitoring Guidance</li>
                            <li>Refrain from going to public places and contacting other people
                                except for necessary activities such as commuting to and from work)</li>
                            <li>Wear a facemask in all indoor spaces (including public transportation)
                                and outdoor spaces where 2-meter distancing is unavailable</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>