<div>
    <section class="hero">
        <div class="hero-body">
            <div class="container has-text-centered">
              <div class="mx-6 my-6">
                <p class="title has-text-weight-bold has-text-dark is-family-sans-serif">
                    SIJAQ
                </p>
                <p class="subtitle has-text-weight-semi-bold is-size-7 has-text-grey is-family-primary">
                    Satellite Integrated Joint
                  Monitoring of Air Quality
                </p>
              </div>
              <div style="display: flex; justify-content: center">
                <div class="tile is-ancestor">
                  <div class="tile is-vertical">
                    <div class="tile is-parent">
                      <article class="tile is-child box sijaq3">
                        <a href="https://sijaq3.sijaq.org/">
<!--                       give shadow on img />-->
                          <span class="mb-3">
                              <img src="assets/images/sijaq.png" />
                          </span>
                          <p class=" has-text-weight-bold is-size-3 is-family-sans-serif">
                            SIJAQ/ASIA-AQ
                          </p>
                          <p class="has-text-weight-semibold is-size-4">
                            2023
                          </p>
                        </a>
                      </article>
                    </div>
                    <div class="tile">
                      <div class="tile is-parent">
                        <article class="tile is-child box sijaq2">
                          <a href="http://sijaq2.sijaq.org/">
                            <span class="mb-3">
                                <img src="assets/images/sijaq.png" />
                            </span>
                            <p class="has-text-weight-bold is-size-5 is-family-sans-serif">
                              SIJAQ
                            </p>
                            <p class="has-text-weight-semibold is-size-6">
                              2022
                            </p>
                          </a>
                        </article>
                      </div>
                      <div class="tile is-parent">
                        <article class="tile is-child box sijaq">
                          <a [routerLink]="['/home']">
                            <span class="mb-3">
                                <img src="assets/images/sijaq.png" />
                            </span>
                            <p class="has-text-weight-bold is-family-sans-serif is-size-5">
                              SIJAQ/GMAP
                            </p>
                            <p class="has-text-weight-semibold is-size-6">
                              2021
                            </p>
                          </a>
                        </article>
                      </div>
                      <div class="tile is-parent">
                        <article class="tile is-child box aircraft-others">
                          <a href="https://sijaq2.sijaq.org/others/aircraft-others">
                            <span class="mb-3">
                                <img src="assets/images/sijaq.png" />
                            </span>
                            <p class="has-text-weight-bold is-size-5 is-family-sans-serif">
                              AIRCRAFT
                            </p>
                            <p class="has-text-weight-semibold is-size-6">
                              (Others)
                            </p>
                          </a>
                        </article>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
</div>
