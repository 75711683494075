import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as dateFnsFp from "date-fns/fp";
import { DateRange } from 'src/app/common/calendar/time-range-slider/time-range-slider.component';
import { ValidatorService } from 'src/app/common/validation/validation.service';

@Component({
  selector: 'app-modelimage',
  templateUrl: './modelimage.component.html',
  styleUrls: ['./modelimage.component.css']
})
export class ModelimageComponent implements OnInit, AfterViewInit {

  form: FormGroup;
  domain = ['27km', '09km', '03km'];

  domainOrSection: string;
  domainOrSection_folder: string;

  currentDate: Date = new Date();
  sliderDate: Date = new Date();
  verticalCurtainDate!: Date;

  imageArray!: any[];

  constructor(private router: Router, private fb: FormBuilder, public validator: ValidatorService, private changeRef: ChangeDetectorRef) {
    
  }

  ngAfterViewInit(): void {
    this.changeRef.detectChanges();
  }

  ngOnInit(): void {
    this.domainOrSection = [this.domain[0], "SURFACE"].join("_");
    this.domainOrSection_folder = this.domain[0];

    this.form = this.fb.group({
      kind: ['spatial', [Validators.required]],
      domain: [this.domain[0], [Validators.required]],
      variable: ['PM25', [Validators.required]],
      section: ['CROSS01', [Validators.required]]
    });

    this.form.valueChanges.subscribe(value => {
      let section = value.section;

      if (value.kind.trim() == 'spatial') {
        this.domainOrSection_folder = value.domain;
        this.domainOrSection = [value.domain, "SURFACE"].join("_");
      } else {
        this.domainOrSection_folder = section.startsWith('CROSS') ? 'cross_section' : 'curtain';
        this.domainOrSection = value.section.trim();
      }

      this.makeImageData();
    });
  }

  isSelectedVertical() {
    return this.form.value.kind == 'vertical';
  }

  isSelectedCurtain(){
    return this.form.value.section.toLowerCase().includes('curtain');
  }


  makeImageData() {
    this.imageArray = []; 

    const modelData = {
      "CMAQ-GIST": {name: "CMAQ-GIST", team: "GIST"},
      "CMAQ": {name: "CMAQ", team: "UNIST"},
      "WRF-CHEM": {name: "WRF-Chem", team: "UNIST"},
      "CAMX": {name:  "CAMx", team: "AJOU"},
      "GEOS-CHEM": {name: "GEOS-Chem", team: "SNU"},
      "ENSEMBLE": {name: "", team: "ENSEMBLE"}      
    }

    let kind = this.form.value.kind;
    let variable = this.form.value.variable;
    let key = this.router.url.split("/")[3].toUpperCase();

    const dirs = ["/image", dateFnsFp.format("yyyy/MM/dd")(this.currentDate), "modeling", key.toLowerCase(), kind, this.domainOrSection_folder].join("/");
    const fileDate = this.isSelectedCurtain() ? this.verticalCurtainDate : this.sliderDate;
    const fileName = modelData[key]?.team + (modelData[key]?.name == (null || "") ? "" + modelData[key]?.name + "_" : "_" + modelData[key]?.name + "_") + variable + "_" + this.domainOrSection + "_" + dateFnsFp.format("yyyyMMddHH")(fileDate) + ".png";


    this.imageArray.push({
      title: key,
      src: dirs + "/" + fileName
    })

    //console.log(this.imageArray);
  }

  getReady(date: DateRange) {
    this.currentDate = date.current;
    this.sliderDate = date.start;
    this.verticalCurtainDate = date.start;
    this.makeImageData();
  }

  getDateChange(date: DateRange) {
    this.currentDate = date.current;
    this.sliderDate = date.start;
    this.verticalCurtainDate = date.start;
    this.makeImageData();
  }

  getValueChange(date: Date) {
    this.sliderDate = date;
    this.makeImageData();
  }
}
