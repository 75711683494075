import { Component, OnInit } from '@angular/core';
import * as dateFns from 'date-fns';

@Component({
  selector: 'app-weather-map',
  templateUrl: './weather-map.component.html',
  styleUrls: ['./weather-map.component.css']
})
export class WeatherMapComponent implements OnInit {

  rootUrl = "https://www.kma.go.kr/repositary/image/cht/img/";

  constructor() { }

  ngOnInit(): void {
    this.makeImageArray();
  }

  imageArray: any[];

  headers = [['SFC'], ['850hPa'], ['500hPa']];
  data = [
    [[this.rootUrl+"surf", dateFns.format(new Date(), "yyyyMMdd")+"00.png"].join("_")], 
    [[this.rootUrl+"up85", dateFns.format(new Date(), "yyyyMMdd")+"00.png"].join("_")], 
    [[this.rootUrl+"up50", dateFns.format(new Date(), "yyyyMMdd")+"00.png"].join("_")], 
  ];

  makeImageArray(){
    this.imageArray = [
      {
        title: 'SFC',
        src: [this.rootUrl+"surf", dateFns.format(new Date(), "yyyyMMdd")+"00.png"].join("_")
      },
      {
        title: '850hPa',
        src: [this.rootUrl+"up85", dateFns.format(new Date(), "yyyyMMdd")+"00.png"].join("_")
      },
      {
        title: '500hPa',
        src: [this.rootUrl+"up50", dateFns.format(new Date(), "yyyyMMdd")+"00.png"].join("_")
      }
    ]
    
  }

}
