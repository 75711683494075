import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import * as dateFns from "date-fns";
import * as _ from 'lodash';

@Component({
  selector: 'app-kalion',
  templateUrl: './kalion-korea.component.html',
  styleUrls: ['./kalion-korea.component.css']
})
export class KalionKoreaComponent {

  tabs:string[] = [ "Seoul", "Ulsan", "Gosan"];

  current:any = 0;
  currentName:any = this.tabs[0];

  selectedDate: Date = new Date();

  rootUrl = "https://finep.miraeclimate.com/image/";

  imageArray!:any[];

  constructor(private changeRef: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.changeRef.detectChanges();
  }

  clickTab(tabid?: number, tabname?:string) {
    this.current = tabid;
    this.currentName = tabname;
    this.makeImageArray();
  }

  titles:string[] = [
    "Backscattering Coefficient",
    "Mass concentration",
    "Polarization",
    "Classification",
    "Extinction Coefficient"
  ];

  filenames:string[] = [
    "ABC",
    "Mass", 
    "TDR",
    "Type",
    "EXT"
  ];
 
  makeImageArray(): any{  
    this.imageArray = [];

    this.filenames.map((keyword:string, index:number) => {
      this.imageArray?.push({
        title: this.titles[index],
        src: this.rootUrl+[this.currentName, keyword, this.getFormatDays() +".jpg"].join("_")
      });
    });
    // return this.data?.filenames.map((keyword) => {
    //   return [
    //     this.rootUrl+[this.currentName, keyword[0], this.getFormatDays() +".jpg"].join("_"),
    //     this.rootUrl+[this.currentName, keyword[1], this.getFormatDays() +".jpg"].join("_")
    //   ]
    // });
  }

  getFormatDays(){
    let tempDate = _.cloneDeep(this.selectedDate);

    return [dateFns.format(tempDate.setDate(tempDate.getDate() - 6), "yyyyMMdd"), dateFns.format(this.selectedDate, "yyyyMMdd")].join("-");
  }
  
  getDateChange(date: Date){
    this.selectedDate = date;
    this.makeImageArray();
  }  


}
