<div class="buttons is-right" *ngIf="auth.hasAuthentication()">
    <a class="button" [routerLink]="[ 'new' ]">
        <i class="fas fa-pen mr-2"></i>
        <span>Upload</span>
    </a>
</div>

<app-basic-table [data]="data" [headers]="headers" (open)="open($event)">
    <ng-template let-element let-index="index" #row>
        <td class="has-text-centered is-vertical-align" *ngIf="this.types != null">
            {{ getTypes(element.type) }} 
        </td>
        <td class="has-text-centered is-vertical-align">
            {{ element.title }}
        </td>
        <td class="has-text-centered is-vertical-align">
            {{ element.updatedAt | date: 'yyyy-MM-dd HH:mm:ss' }}
        </td>
        <td class="has-text-centered is-vertical-align">
            {{ element.editor }}
        </td>
        <td class="has-text-centered">
            <div *ngIf="element.files?.length > 0"> 
                <div>
                    <a class="button is-white" (click)="downloadFiles($event, element)">
                        <i class="fas fa-download"></i>
                    </a>
                </div>
                <div>
                    <span class="is-size-7">
                        <span>{{ fileCount(element) }}</span>
                        <span> ({{ totalUploadedFileSize(element) | fileSize }})</span>
                    </span>
                </div>
            </div>
        </td>
    </ng-template>
</app-basic-table>

<div class="mt-4 is-mobile" *ngIf="max > 0" >    
    <div class="is-10">
        <ngx-bulma-pagination [max]="max" (pageChange)="onPageChange($event)"></ngx-bulma-pagination>
    </div>
</div>