import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FileType } from './common/rich-text/file-type.enum';
import { EnhancedRichTextEditorComponent } from './common/rich-text/rich-text-editor/enhanced-rich-text-editor/enhanced-rich-text-editor.component';
import { EnhancedRichTextViewerComponent } from './common/rich-text/rich-text-viewer/enhanced-rich-text-viewer/enhanced-rich-text-viewer.component';
import { AuthGuard } from './http/auth.guard';
import { AuthResolver } from './http/auth.resolver';
import { DoorComponent } from './main/door/door.component';
import { MainComponent } from './main/main.component';
import { ProfileComponent } from './main/section/auth/profile/profile.component';
import { SignInComponent } from './main/section/auth/sign-in/sign-in.component';
import { SignUpComponent } from './main/section/auth/sign-up/sign-up.component';
import { FormComponent as MemberFormComponent } from './main/section/content-with-sidebar/admin/members/form/form.component';
import { ListComponent as MemberListComponent } from './main/section/content-with-sidebar/admin/members/list/list.component';
import { PermittedComponent as PermittedMembersComponent } from './main/section/content-with-sidebar/admin/members/list/permitted/permitted.component';
import { WaitingComponent as WaitingMembersComponent } from './main/section/content-with-sidebar/admin/members/list/wating/wating.component';
import { MembersComponent as MemberManagementComponent } from './main/section/content-with-sidebar/admin/members/members.component';
import { ContentWithSidebarComponent } from './main/section/content-with-sidebar/content-with-sidebar.component';
import { AircraftMeasurementsComponent } from './main/section/content-with-sidebar/field-campaign-data-collected/aircraft/aircraft.component';
import { CarMobileDoasComponent } from './main/section/content-with-sidebar/field-campaign-data-collected/car-doas/car-doas.component';
import { GcasMeasurementsComponent } from './main/section/content-with-sidebar/field-campaign-data-collected/gcas/gcas.component';
import { GroundSupersiteComponent } from './main/section/content-with-sidebar/field-campaign-data-collected/ground-supersite/ground-supersite.component';
import { InformationExchangeComponent } from './main/section/content-with-sidebar/field-campaign-data-collected/information-exchange/information-exchange.component';
import { MaxDoasComponent } from './main/section/content-with-sidebar/field-campaign-data-collected/max-doas/max-doas.component';
import { MicroMeteorologicalAnalysisComponent } from './main/section/content-with-sidebar/field-campaign-data-collected/micro-meteorological/micro-meteorological.component';
import { PandoraListComponent } from './main/section/content-with-sidebar/field-campaign-data-collected/pandora-list/pandora-list.component';
import { SatelliteRgbComponent } from './main/section/content-with-sidebar/field-campaign-data-collected/satellite-rgb/satellite-rgb.component';
import { AnnouncementsComponent } from './main/section/content-with-sidebar/news/announcements/announcements.component';
import { AmbientAirConcentrationsComponent } from './main/section/content-with-sidebar/routine-data-collected/ambient-air-concentrations/ambient-air-concentrations.component';
import { AeronetChinaComponent } from './main/section/content-with-sidebar/routine-data-collected/ground-based-remote-sensingdata/aeronet-china/aeronet-china.component';
import { AeronetKoreaComponent } from './main/section/content-with-sidebar/routine-data-collected/ground-based-remote-sensingdata/aeronet-korea/aeronet-korea.component';
import { KalionKoreaComponent } from './main/section/content-with-sidebar/routine-data-collected/ground-based-remote-sensingdata/kalion-korea/kalion-korea.component';
import { PandoraSnuComponent } from './main/section/content-with-sidebar/routine-data-collected/ground-based-remote-sensingdata/pandora-snu/pandora-snu.component';
import { PandoraUnistComponent } from './main/section/content-with-sidebar/routine-data-collected/ground-based-remote-sensingdata/pandora-unist/pandora-unist.component';
import { ComparisonComponent } from './main/section/content-with-sidebar/routine-data-collected/modeling/comparison/comparison.component';
import { ModelimageComponent } from './main/section/content-with-sidebar/routine-data-collected/modeling/modelimage/modelimage.component';
import { RoutineDataCollectedComponent } from './main/section/content-with-sidebar/routine-data-collected/routine-data-collected.component';
import { CloudRgbComponent } from './main/section/content-with-sidebar/routine-data-collected/satellite/cloud/cloud-rgb/cloud-rgb.component';
import { CloudTsComponent } from './main/section/content-with-sidebar/routine-data-collected/satellite/cloud/cloud-ts/cloud-ts.component';
import { CloudComponent } from './main/section/content-with-sidebar/routine-data-collected/satellite/cloud/cloud.component';
import { GemsComponent } from './main/section/content-with-sidebar/routine-data-collected/satellite/gems/gems.component';
import { TropomiComponent } from './main/section/content-with-sidebar/routine-data-collected/satellite/tropomi/tropomi.component';
import { CurrentComponent } from './main/section/content-with-sidebar/routine-data-collected/weather-analysis/current/current.component';
import { ForecastComponent } from './main/section/content-with-sidebar/routine-data-collected/weather-analysis/forecast/forecast.component';
import { HomeComponent } from './main/section/home/home.component';

function _boardRoutes(
  listComponent: any,
  editorOption?: {
    storeKey?: string,
    fileType?: FileType,
    type?: string[][]
  }) {

  editorOption = Object.assign({
    hiddenSubMenu: true
  }, editorOption);

  return [
    { path: '', component: listComponent, data: editorOption },
    { path: 'view/:id', component: EnhancedRichTextViewerComponent, data: editorOption },
    { path: 'new', component: EnhancedRichTextEditorComponent, data: editorOption, canActivate: [AuthGuard] },
    { path: 'edit/:id', component: EnhancedRichTextEditorComponent, data: editorOption, canActivate: [AuthGuard], resolve: { data: AuthResolver } },
    { path: '**', redirectTo: '', pathMatch: 'full' }
  ];
}

const boardOption = {
  doas: [["MPIC", "BIRA", "U. of Bremen"]],
  maxdoas: [["MPIC", "BIRA", "U. of Bremen", "JAMSTEC"]],
  pandora: [["NIER1", "NIER2", "NIER3"]]
}

let year = "";

const routes: Routes = [
  { path: '', component: DoorComponent},
  {
    path: `${year}`, component: MainComponent,
    children: [
      { path: 'home', component: HomeComponent },
      { path: 'signin', component: SignInComponent, canActivate: [AuthGuard] },
      { path: 'signup', component: SignUpComponent, canActivate: [AuthGuard] },
      { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
      { path: 'announcements', component: AnnouncementsComponent, canActivate: [AuthGuard] },
      //{ path: 'announcements', component: AnnouncementsComponent },
      {
        path: '', component: ContentWithSidebarComponent,
        children: [
          {

            path: 'routine_data_collected',
            canActivate: [AuthGuard],
            component: RoutineDataCollectedComponent,
            children: [
              { path: 'ambient_air_concentrations', component: AmbientAirConcentrationsComponent },
              {
                path: 'ground_based_remote_sensingdata',
                children: [
                  { path: 'aeronet-korea', component: AeronetKoreaComponent },
                  { path: 'aeronet-china', component: AeronetChinaComponent },
                  { path: 'kalion-korea', component: KalionKoreaComponent },
                  { path: 'pandora-SNU', component: PandoraSnuComponent },
                  { path: 'pandora-UNIST', component: PandoraUnistComponent },
                  { path: '**', redirectTo: 'aeronet-korea', pathMatch: 'full' }
                ]
              },
              {
                path: 'satellite-data',
                children: [
                  {
                    path: 'gems',
                    children: [
                      { path: 'aod_443nm', component: GemsComponent },
                      { path: 'no2', component: GemsComponent },
                      { path: 'o3t', component: GemsComponent },
                      { path: 'hcho', component: GemsComponent },
                      { path: '**', redirectTo: 'aod_443nm', pathMatch: 'full' }
                    ]
                  },
                  {
                    path: 'tropomi',
                    children: [
                      { path: 'no2', component: TropomiComponent },
                      { path: 'o3t', component: TropomiComponent },
                      { path: 'hcho', component: TropomiComponent },
                      { path: '**', redirectTo: 'no2', pathMatch: 'full' }
                    ]
                  },
                  {
                    path: 'cloud', component: CloudComponent,
                    children: [
                      { path: 'rgb', component: CloudRgbComponent },
                      { path: 'ts', component: CloudTsComponent },
                      { path: '**', redirectTo: 'rgb', pathMatch: 'full' }
                    ]
                  },
                  { path: '**', redirectTo: 'gems', pathMatch: 'full' }
                ]
              },
              {
                path: 'modeling-data',
                children: [
                  { path: 'cmaq-gist', component: ModelimageComponent },
                  { path: 'cmaq', component: ModelimageComponent },
                  { path: 'wrf-chem', component: ModelimageComponent },
                  { path: 'camx', component: ModelimageComponent },
                  { path: 'geos-chem', component: ModelimageComponent },
                  { path: 'ensemble', component: ModelimageComponent },
                  { path: 'comparison', component: ComparisonComponent },
                  { path: '**', redirectTo: 'cmaq-gist', pathMatch: 'full' }
                ]
              },
              {
                path: 'weather-analysis',
                children: [
                  { path: 'current', component: CurrentComponent, data: { hiddenSubMenu: true } },
                  { path: 'forecast', component: ForecastComponent, data: { hiddenSubMenu: true } },
                  { path: '**', redirectTo: 'current', pathMatch: 'full' }
                ]
              },
              { path: '**', redirectTo: 'ambient_air_concentrations', pathMatch: 'full' }
            ]
          },
          {
            path: 'field_campaign_data_collected',
            canActivate: [AuthGuard],
            children: [
              {
                path: 'aircraft',
                children: _boardRoutes(AircraftMeasurementsComponent)
              },
              {
                path: 'ground_supersite',
                children: _boardRoutes(GroundSupersiteComponent)
              },
              {
                path: 'gcas',
                children: _boardRoutes(GcasMeasurementsComponent)
              },
              {
                path: 'max-doas',
                children: _boardRoutes(MaxDoasComponent, { type: boardOption.maxdoas })
              },
              {
                path: 'car-doas',
                children: _boardRoutes(CarMobileDoasComponent, { type: boardOption.doas })
              },
              {
                path: 'pandora',
                children: _boardRoutes(PandoraListComponent, { type: boardOption.pandora })
              },
              {
                path: 'micro_meteorology',
                children: _boardRoutes(MicroMeteorologicalAnalysisComponent)
              },
              {
                path: 'satellite_rgb',
                children: _boardRoutes(SatelliteRgbComponent),
                data: { title: "Satellite - GK2A AMI RGB + Aircraft path" }
              },
              {
                path: 'information_exchange',
                children: _boardRoutes(InformationExchangeComponent)
              },
              { path: '**', redirectTo: 'aircraft', pathMatch: 'full' }
            ]
          },
          // {
          //   path: 'site-link',
          //   children: [
          //     {
          //       path: 'list',
          //       component: LinkComponent
          //     },
          //     { path: '**', redirectTo: 'list', pathMatch: 'full' }
          //   ]
          // },
          {
            path: 'admin',
            canActivate: [AuthGuard],
            children: [
              {
                path: 'members', component: MemberManagementComponent,
                children: [
                  {
                    path: 'list', component: MemberListComponent,
                    children: [
                      { path: 'permitted', component: PermittedMembersComponent },
                      { path: 'waiting', component: WaitingMembersComponent },
                      { path: '**', redirectTo: 'permitted', pathMatch: 'full' }
                    ]
                  },
                  { path: 'edit', component: MemberFormComponent },
                  { path: '**', redirectTo: 'list', pathMatch: 'full' }
                ]
              },
              { path: '**', redirectTo: 'members', pathMatch: 'full' }
            ]
          },
          { path: '**', redirectTo: '', pathMatch: 'full' }
        ]
      },
      { path: '**', redirectTo: '', pathMatch: 'full' }
    ],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
