import { ChangeDetectorRef, Component } from '@angular/core';
import * as dateFns from "date-fns";

@Component({
  selector: 'app-aeronet-korea',
  templateUrl: './aeronet-korea.component.html',
  styleUrls: ['./aeronet-korea.component.css']
})
export class AeronetKoreaComponent {

  tabs: string[] = [
    "Socheongcho",
    "Anmyon",
    "Seoul_SNU",
    "Yonsei_University",
    "Gangneung_WNU"
  ];

  current:any = 0;
  currentName:any = this.tabs[0];
  selectedDate: Date = new Date();

  rootUrl = "https://finep.miraeclimate.com/image/";

  imageArray!: any[];

  constructor(private changeRef: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.changeRef.detectChanges();
  }

  clickTab(tabid?: number, tabname?:string) {
    this.current = tabid;
    this.currentName = tabname;
    this.makeImageArray();
  }

  titles: string[] = [
    "Daily AOD (UTC)",
    "AOD Monthly Timeseries (UTC)",
    "Fine Mode AOD & Coarse Mode AOD (UTC)",
    "FMA&CMA AOD Monthly Timeseries (UTC)",
    "당일 Fine Mode Fraction (UTC)",
    "FMF Monthly Timeseries (UTC)"
  ]

  filenames:string[] = [
    "aod", "fc-aod", "fmf"
  ]

  makeImageArray(): any{   
    this.imageArray = [];
    let num = 0;

    this.filenames.map((keyword:string, index:number) => {
      this.imageArray?.push({
          title: this.titles[index+num],
          src: this.rootUrl+[this.currentName, keyword, dateFns.format(this.selectedDate, "yyyy-MM-dd") + ".gif"].join("-")
      });
      this.imageArray?.push({
          title: this.titles[index+(num+1)],
          src: this.rootUrl+[this.currentName, keyword, dateFns.format(this.selectedDate, "yyyy-MM") + ".gif"].join("-")
      });        
    });
  }

  getDateChange(date: Date){
    this.selectedDate = date;
    this.makeImageArray();
  }  

}
