<section class="hero is-info">
    <div class="hero-body ">
        <div class="columns is-vcentered">
            <div class="column title has-text-weight-bold is-size-2 mb-0 has-text-right">
                <p class="is-family-primary">SIJAQ/GMAP 2021</p>
                <p class="is-family-primary">Campaign</p>
            </div>
            <div class="column title has-text-weight-semibold is-size-4 mb-0 has-text-left">
                <p class="is-family-primary">Inter-comparison: Oct. 4~14</p>
                <p class="is-family-primary">Main period: Oct. 18~Nov. 25</p>
            </div>
        </div>
    </div>
</section>
<section class="hero">
    <div class="hero-body">
        <p class="title has-text-weight-bold is-family-primary mb-6">
            Purpose of GMAP 2021
        </p>
        <div class="subtitle ml-3">
            <div>
                <div class="is-family-primary">
                    • Identify PM2.5 formation processes in SMA (especially, nitrate and OA formation)
                </div>
                <div class="is-family-primary">
                    • Validate GEMS with fiducial reference measurement (considering spatial representativeness)
                </div>
                <div class="is-family-primary">
                    • Scientific papers → RSSR (Rapid Science Synthesis Report)→ FSSR
                </div>
            </div>
        </div>
    </div>
</section>
<section class="hero">
    <div class="hero-body">
        <p class="title has-text-weight-bold is-family-primary mb-6">
            Role and responsibility of participating teams
        </p>
        <div class="subtitle ml-3">
            <div>
                <div class="is-family-primary">
                    • Follow the COVID-19 control and prevention act (guideline will be delivered soon)
                </div>
            </div>
        </div>
    </div>
</section>
<section class="hero">
    <div class="hero-body">
        <p class="title has-text-weight-bold is-family-primary mb-6">
            Data collection, storage and sharing
        </p>
        <div class="subtitle ml-3">
            <div>
                <div class="is-family-primary">
                    • Use data management system through a webpage (https://www.sijaq.org)
                </div>
            </div>
        </div>
    </div>
</section>
<img src="assets/images/sample_mainimage.png">
