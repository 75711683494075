<div class="columns is-multiline my-3 mx-3" *ngFor="let i of rowIndices">
    <div class="column" [ngClass]="columnData[i].style" *ngFor="let c of columnIndices[i]">
        <div class="card">
            <header class="card-header">
                <p class="card-header-title is-size-4">
                    {{ columnData[i]?.info[c].title }}
                </p>
            </header>
            <div class="card-image has-text-centered">
                <figure class="mx-3 my-3">
                    <img [src]="columnData[i]?.info[c].src" />
                </figure>
            </div>
        </div>
    </div>
</div>

