<footer class="footer">
    <div class="columns">
        <!-- <div class="column is-2 ml-3 mr-3">
            <img src="https://via.placeholder.com/400x200?text=footer+logo" />
        </div> -->
        <div>
            <div class="is-size-7">
                Address : 1001-3, 4th Engineering Center, 50, UNIST-gil, Eonyang-eup, Ulju-gun, Ulsan, Republic of Korea
            </div>
            <div class="columns is-size-7 is-gapless">
                <div class="mr-3">
                    Postal code : 44919
                </div>
                <div class="mr-3">
                    <i class="fas fa-phone fa-sm"></i>: 052-217-2835
                </div>
                <div class="mr-3">
                    <i class="fas fa-fax"></i>: 052-217-2836
                </div>
                <div class="mr-3">
                    <i class="fas fa-mail-bulk"></i> : cksong@unist.ac.kr
                </div>
            </div>
            <div class="is-size-7">
                Copyright (C) <span class="has-text-weight-bold">SIJAQ.</span> All rights reserved
            </div>
        </div>
    </div>
</footer>