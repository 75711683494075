import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxGalleryImage } from '@kolkov/ngx-gallery';
import jwt_decode from "jwt-decode";
import { AuthService } from 'src/app/http/auth.service';
import { File } from 'src/app/store/schema';
import { GallerySetting } from '../../gallery-setting';
import { Control } from '../../select/select.component';
import { SelectService } from '../../select/select.service';
import { AbstractEditor } from '../abstract-editor';

@Component({
  selector: 'app-rich-text-viewer',
  templateUrl: './rich-text-viewer.component.html',
  styleUrls: ['./rich-text-viewer.component.css']
})
export class RichTextViewerComponent extends AbstractEditor implements OnInit, OnChanges {

  galleryImages: NgxGalleryImage[] = [];
  galleryOptions = GallerySetting.options;

  @Input()
  gallery: boolean = false;

  @Input()
  view: number;

  @Input()
  date: string;

  @Input()
  files: any[];

  @Input()
  editor: string;

  @Output()
  download = new EventEmitter<File>();

  @Output()
  delete = new EventEmitter<string>();

  @Output()
  edit = new EventEmitter<string>();

  controlGroup: Control[][];

  isShowModalForDeletePost: boolean = false;

  constructor(protected route: ActivatedRoute, protected router: Router, protected auth: AuthService, public select : SelectService) {
    super(route, router);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.controlGroup = this.route.snapshot.data['type'];
  }

  ngOnChanges(_changes: SimpleChanges): void {
    if (this.gallery && this.files) {
      const urls = this.router.url.split("/");
      this.galleryImages = GallerySetting.filesToImages(this.files);
    }
  }

  clickFile(file: File) {
    this.download.emit(file);
  }

  checkDeletePost() {
    this.isShowModalForDeletePost = true;
  }

  getTypes(){
    if(this.type == null) return; 
    
    // 전체 제외
    const types = this.select.decode(this.type);
    return this.controlGroup.map((arr, index) => arr[types[index] - 1]).join(", ");   
  }

  deletePost() {
    this.delete.emit(this.id);
  }

  editPost() {
    this.edit.emit(this.id);
  }

  hasAuthorization() {
    return this.auth.who() == this.editor || this.auth.hasStaffRole();
  }

  get totalUploadedFileSize() {
    return this.files?.map(f => f.size).reduce((p, r) => {
      p += r;
      return p;
    }, 0);
  };
}