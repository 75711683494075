import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import * as dateFns from 'date-fns';
import * as dateFnsFp from 'date-fns/fp';
import * as _ from "lodash";
import { DateRange } from 'src/app/common/calendar/time-range-slider/time-range-slider.component';
import { TimeRangeSliderService } from 'src/app/common/calendar/time-range-slider/time-range-slider.service';

@Component({
  selector: 'app-ambient-air-concentrations',
  templateUrl: './ambient-air-concentrations.component.html',
  styleUrls: ['./ambient-air-concentrations.component.css']
})
export class AmbientAirConcentrationsComponent implements OnInit  {

  rootUrl = "https://finep.miraeclimate.com/image/";

  selectedDate: Date = new Date();
  date = new Date();

  imageArray!: any[];

  //FIXME..
  tempDateFlag: boolean = false;


  constructor(private changeRef: ChangeDetectorRef, private timeRangeSlider: TimeRangeSliderService) {
    
  }
  ngOnInit(): void {
    //this.makeImageArray();
  }

  makeImageArray() {
    this.imageArray = [
      {
        title: "Estimation of PM2.5 ground concentration using statistical techniques",
        src: this.rootUrl+["ground-korea-pm25", _.flow(dateFnsFp.addHours(9), dateFnsFp.format("yyyyMMddHH"))(this.selectedDate) +".png"].join("-")
      }, 
      {
        title: "Estimating PM2.5 ground concentration using satellite data (only available from 9:00 to 16:00)",
        src: this.rootUrl+["PM25_AHI_RTT_hourly_map", this.countDays()+".jpg"].join("_")
      }       
    ];
  }

  ngAfterViewInit(): void {
    this.changeRef.detectChanges();
  }

  getReady(date: DateRange){
    this.selectedDate = dateFns.subHours(date.current, 9);
    this.makeImageArray();
  }

  getDateChange(date: DateRange){
    this.selectedDate = dateFns.subHours(date.current, 9);
    this.timeRangeSlider.resetIndices.emit();
    this.makeImageArray();
  }

  getValueChange(date: Date){
    this.selectedDate = dateFns.subHours(date, 9)
    this.makeImageArray();
  } 

  countDays(){
    let date = dateFns.format(this.selectedDate, "yyyyMMddHH");

    let year = Number(date.substring(0,4));
    let month = Number(date.substring(4,6));
    let day = Number(date.substring(6,8));
    let hour = Number(date.substring(8,10)); 

    let cntdays = 0;
    
    for(let m = 1; m < month; m++){
      cntdays += new Date(year, m, 0).getDate()
    }

    cntdays += day;
    
    return [year, this.fillZeroDays(cntdays), this.fillZeroHours(hour)].join("_");
  }

  fillZeroHours(num){    
    return num.toString().length < 2 ? '0' + num : num;
  }

  fillZeroDays(num){    
    return num.toString().length < 2 ? '00' + num : num.toString().length < 3 ? '0' + num : num;
  }



}
